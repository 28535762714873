/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Breadcrumb from 'components/shared/Breadcrumb'
import ParseContent from 'components/shared/ParseContent'
import Customer from 'components/shared/Customer'
import CaseInfo from 'components/shared/CaseInfo'
import GravityForm from 'components/GravityForm'

// Third Party
import styled, { css } from 'styled-components'

const Page = styled.div`
  h2,
  h3 {
    font-size: ${(props) => props.theme.font.size.xml};
    font-weight: ${(props) => props.theme.font.weight.xl};
    padding-top: 20px;
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
  }

  ${(props) =>
    props.title === 'Algemene voorwaarden' &&
    css`
      & a {
        font-weight: ${props.theme.font.weight.xl};
        color: ${props.theme.color.text.contrast};
        text-decoration: underline;
      }
    `}
`

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...PageFrag
    }
  }
`

const FORM_DEFAULT_ZIPCODE_VALUES = {
  15: 'input_7',
}

const PageTemplate = ({
  data: {
    page: { path, title, acf, yoast_meta: yoast },
  },
  pageContext,
  ...rest
}) => {
  const params = new URLSearchParams(rest.location.search)
  let defaultValues = {}
  let addressText = 'uw adres'

  if (params.get('postcode')) {
    const zipcode = `${params.get('postcode')} ${params.get('huisnummer')}${
      params.get('toevoeging') ? ` (${params.get('toevoeging')})` : ''
    }`
    addressText = `postcode <strong>${zipcode}</strong>`

    defaultValues = {
      [FORM_DEFAULT_ZIPCODE_VALUES[acf.form]]: zipcode,
    }
  }

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <Hero small className="mb-lg-3" content={acf.banner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <Page title={title} className="container">
        <section>
          <div className="container">
            <div className="row">
              {acf.relation ? (
                <>
                  <div className="col-md-8 pt-4">
                    <ParseContent
                      content={acf.texts.content.replace(
                        /\{zipcode\}/g,
                        addressText
                      )}
                      className="mb-5"
                    />
                    {acf.form ? (
                      <GravityForm
                        id={acf.form}
                        defaultValues={defaultValues}
                        brochure
                      />
                    ) : (
                      <br />
                    )}
                    <img
                      height="1"
                      width="1"
                      className="d-none"
                      alt=""
                      src={`https://px.ads.linkedin.com/collect/?pid=3619313&conversionId=4796625&fmt=gif`}
                    />
                  </div>
                  <div className="col-md-4 px-lg-5">
                    {acf.date && acf.time && (
                      <>
                        <p className="color-secondary font-size-sm font-weight-xl mb-0">
                          Datum
                        </p>
                        <ParseContent content={acf.date} />
                        <p className="color-secondary font-size-sm font-weight-xl mb-0 mt-3">
                          Tijd
                        </p>
                        <ParseContent content={acf.time} />
                      </>
                    )}
                    <p className="color-secondary font-size-sm font-weight-xl mb-0 mt-3">
                      Clear Mind Regisseurs
                    </p>
                    <div className="pt-5 mt-5">
                      <Customer content={acf.relation[0]} />
                      {acf.relation[1] ? (
                        <Customer content={acf.relation[1]} />
                      ) : (
                        <p />
                      )}
                      {acf.relation[2] ? (
                        <Customer content={acf.relation[2]} />
                      ) : (
                        <p />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                acf.texts.content && (
                  <ParseContent content={acf.texts.content} />
                )
              )}
            </div>
          </div>
        </section>
      </Page>
    </Layout>
  )
}

export default PageTemplate
